import React, { useState, useEffect } from 'react';
import { alertActions, menuActions, userActions, advertismentAction } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { bannerTypeConstants } from '../../constants';
const publicIp = require("public-ip");


const PreRegistration = (props) => {
    const dispatch = useDispatch();
    const [siteConstants, setSiteConstants] = useState([]);
    const [totalUser, setTotalUser] = useState(0);
    const [link, setLink] = useState({});
    const [menuTitle, setMenuTitle] = useState([]);
    const [randomAdvertismentList, setRandomAdvertismentList] = useState([]);
    const [randomTextAdvertismentList, setRandomTextAdvertismentList] = useState([]);

    const [timeLeft, setTimeLeft] = useState({});
    const [isLaunched, setIsLaunched] = useState(false);
    const [isExpired, setIsExpired] = useState(false);

    const { BANNER_ADS, TEXT_TEMPLATE1, TEXT_TEMPLATE2, BANNER_FIXED_HEIGHT, BANNER_FIXED_WIDTH } = bannerTypeConstants;
    console.log("randomAdvertismentList", randomAdvertismentList)

    const { language, getCountryList, checkReferLink, getSetting, getCount, getMenu }
        = useSelector(
            state => {
                return ({
                    language: state.language,
                    getCountryList: state.getCountryList,
                    checkReferLink: state.checkReferLink,
                    getSetting: state.getSetting,
                    getCount: state.getCount,
                    getMenu: state.getMenu
                })
            });

    useEffect(() => {
        if (Object.keys(language).length > 0) {
            setSiteConstants(language.siteConstants);
        }

    }, [language]);


    const getrandomList = async () => {
        let ipAddress = await publicIp.v4();
        let objBannerAds = {
            type: BANNER_ADS,
            perPage: bannerTypeConstants.FOOTER_TEXT_ADS_LIMIT,
            ipAddress
        };
        let objTextAds = {
            type: TEXT_TEMPLATE1,
            perPage: bannerTypeConstants.FOOTER_TEXT_ADS_LIMIT,
            ipAddress
        };
        dispatch(advertismentAction.getRandomAdvertismentList(apiConstants.GET_RANDOM_ADVERTISMENT, objBannerAds));
        dispatch(advertismentAction.getTextRandomAdvertismentList(apiConstants.GET_RANDOM_ADVERTISMENT, objTextAds));

    }

    useEffect(() => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('planType');

        let path = props.location.pathname
        const splitData = path.split('/signup/');

        const referenceLink = splitData[1];
        if (referenceLink !== null && referenceLink !== undefined) {
            let obj = { 'refer_link': referenceLink }
            dispatch(userActions.checkReferLink(apiConstants.CHECK_REFER_LINK, obj));
        }
        dispatch(alertActions.clear());
        dispatch(userActions.getAdminReferal(apiConstants.GET_ADMIN_REFERAL));
        dispatch(userActions.getCount(apiConstants.GET_REGISTERED_USER));
        dispatch(userActions.getCountryList(apiConstants.GET_COUNTRY_CODE));
        dispatch(menuActions.getMenu(apiConstants.GET_MENU));
        getrandomList();
        setTimeout(() => {
            dispatch(userActions.getSetting(apiConstants.GET_SETTINGS));
        }, 300);
    }, []);

    useEffect(() => {

        if (Object.keys(getSetting).length > 0) {
            if (getSetting.isGetSetting) {
                if (getSetting.getSetting.data) {

                    const { data } = getSetting.getSetting;

                    setLink({
                        facebookLink: data.data.facebookLink !== null && data.data.facebookLink !== '' ? data.data.facebookLink : '#',
                        telegramLink: data.data.telegramLink !== null && data.data.telegramLink !== '' ? data.data.telegramLink : '#',
                        instagramLink: data.data.instagramLink !== null && data.data.instagramLink !== '' ? data.data.instagramLink : '#',
                        linkedInLink: data.data.linkedInLink !== null && data.data.linkedInLink !== '' ? data.data.linkedInLink : '#',
                        twitterLink: data.data.twitterLink !== null && data.data.twitterLink !== '' ? data.data.twitterLink : '#',
                        tiktokLink: data.data.tiktokLink !== null && data.data.tiktokLink !== '' ? data.data.tiktokLink : '#',
                        youtubeLink: data.data.youtubeLink !== null && data.data.youtubeLink !== '' ? data.data.youtubeLink : '',
                        registerLink: data.data.registerLink !== null && data.data.registerLink !== '' ? data.data.registerLink : '',
                        fundLink: data.data.fundLink !== null && data.data.fundLink !== '' ? data.data.fundLink : '',
                        ppt: data.data.ppt !== null && data.data.ppt !== '' && data.data.ppt.length > 0 ? data.data.ppt : '#',
                        pdf: data.data.pdf !== null && data.data.ppt !== '' && data.data.ppt.length > 0 ? data.data.pdf : '#'
                    })

                }
            }
        }
        if (Object.keys(getCount).length > 0) {
            if (getCount.isGetCount) {
                if (getCount.getCount.data) {
                    const { data } = getCount.getCount;
                    setTotalUser(data.data.totalUsers)
                }
            }
        }
    }, [getCountryList, checkReferLink, getSetting, getCount]);

    useEffect(() => {
        if (Object.keys(getMenu).length > 0) {
            if (getMenu.getMenu !== undefined) {
                const { data } = getMenu.getMenu;
                setMenuTitle(data.data)
            }
        }
    }, [getMenu])

    const bannerHtml2 = (data, index) => {
        return (
            <div key={index} className='col-md-6 home-banner'>
                <div className='p-1 m-1 text-center div-center'>
                    <a href={data.link} target='blank'>
                        <img className="ad-banner" style={{ 'height': BANNER_FIXED_HEIGHT + 'px' }} src={data.files ? data.files : '#'} alt="logo" /></a>
                </div>
            </div>
        )
    }

    const { getRandomAdvertismentList, getTextRandomAdvertismentList } = useSelector((state) => ({
        getTextRandomAdvertismentList: state.getTextRandomAdvertismentList,
        getRandomAdvertismentList: state.getRandomAdvertismentList,
    }));

    useEffect(() => {
        if (getRandomAdvertismentList) {
            if (Object.keys(getRandomAdvertismentList).length > 0) {
                if (getRandomAdvertismentList.getRandomAdvertismentList) {
                    if (getRandomAdvertismentList.getRandomAdvertismentList.data) {
                        const { data } = getRandomAdvertismentList.getRandomAdvertismentList.data.data;
                        if (data.length > 0) {
                            setRandomAdvertismentList(data);

                        }
                    }
                }
            }
        }
    }, [getRandomAdvertismentList]);

    useEffect(() => {
        if (getTextRandomAdvertismentList) {
            if (Object.keys(getTextRandomAdvertismentList).length > 0) {
                if (getTextRandomAdvertismentList.getTextRandomAdvertismentList && getTextRandomAdvertismentList.getTextRandomAdvertismentList.data) {
                    const { data } = getTextRandomAdvertismentList.getTextRandomAdvertismentList.data.data;
                    if (data.length > 0) {
                        setRandomTextAdvertismentList(data);
                    }
                }
            }
        }
    }, [getTextRandomAdvertismentList]);

    const bannerHtml1 = (data, index) => {
        return (

            <div className={`text-ellipsis card m-1 register-banner-layout  mb-3 ${data.type === TEXT_TEMPLATE1 ? 'text-secondary bg-white' : 'text-white bg-info'}`}
                style={{ 'height': BANNER_FIXED_HEIGHT + 'px', width: BANNER_FIXED_WIDTH + 'px' }}>
                <div className={`text-uppercase p-2 text-primary fw-600 ${data.type === TEXT_TEMPLATE1 ? 'border-bottom border-secondary' : 'border-light-secondary'} text-center`}>{data.bannerTitle ? data.bannerTitle : 'Header'}</div>
                <div className="template-card-layout text-center m-2 d-flex justify-content-center flex-column">

                    <small className="card-text">
                        {data.bannerDescription?.bannerdescriptionLine1 ? data.bannerDescription.bannerdescriptionLine1 :
                            'Some quick example'}</small>
                    <small className="card-text">
                        {data.bannerDescription?.bannerdescriptionLine2 ? data.bannerDescription.bannerdescriptionLine2 :
                            'Some quick example text '}</small>
                    <small className="card-text">
                        {data.bannerDescription?.bannerdescriptionLine3 ? data.bannerDescription.bannerdescriptionLine3 :
                            'Some example'}</small>
                </div>
                <a key={index} className={`text-center g-13 mx-1 ${data.type === TEXT_TEMPLATE1 ? 'text-secondary' : 'text-white'}`} style={{ textDecoration: 'none' }} data-title={data.link} href={data.link} target="blank">
                    <small className='text-primary fw-600'>Click Here Now </small>
                </a>
            </div>

        )
    }

    return (
        <>
            <section className="pre-registration-page" style={{ backgroundImage: `url('/assets/images/BGR.png')` }}>
                {/* Menu bar */}
                <div className='d-flex flex-wrap justify-content-around text-light p-1'>
                    {menuTitle && menuTitle.length > 0 ?
                        menuTitle.map((x, index) => {
                            return (<a key={index} className='text-capitalize text-light font-weight-bold m-2'
                                href={"/" + x.menuName.toLowerCase() + '-page' + '/' + x.menuName.toLowerCase()}>{x.menuName}</a>)
                        })
                        : ''}
                    {/* <a className='text-light font-weight-bold m-2' href="/about-us">About Us</a> */}
                    <a className='text-light font-weight-bold m-2' href="/contact-us">Contact</a>
                    <a className='text-light font-weight-bold m-2' href="/competition">Competition</a>
                    {/* <a className='text-light font-weight-bold m-2' href="/mission-vision">Mission Vision</a> */}
                    <a className='text-light font-weight-bold m-2' href="/marketing">Presentation</a>
                    <a className='text-light font-weight-bold m-2' href="/video-files">How to Register ?</a>
                    <a className='text-light font-weight-bold m-2' href="/faq">FAQ</a>
                    <div id="google_translate_element" className='register-page-translate'></div>

                </div>

                {/* iframe and signin and signup div className="top-pre-registration-page"*/}
                <div>

                    <div className='pre-page-inner-content p-3'>

                        <div>
                            <div className="row justify-content-center">
                                <div className="col-md-3 mb-4">
                                </div>
                                <div className="col-md-6 mb-4">
                                    <div className="logo-sec">
                                        <a href="/"><img src="/assets/images/pre-logo.png" alt="logo" /></a>
                                    </div>
                                </div>
                                <div className="col-md-3 mb-4 d-flex align-items-center justify-content-end">
                                    <span className="user-count">
                                        <i className="fa fa-users" aria-hidden="true"></i>
                                        <button>{totalUser}</button>
                                    </span>
                                </div>
                            </div>
                            <div className="row d-flex flex-column align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="video-sec">
                                        {link.youtubeLink || link.fundLink || link.registerLink ?

                                            link.youtubeLink !== undefined && link.youtubeLink !== '' ?
                                                <iframe key="a" title='poster' frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; 
                                            gyroscope; picture-in-picture" allowFullScreen src={link.youtubeLink}>
                                                </iframe> : <img src="/assets/images/poster-video.png" alt="poster-img" />

                                            : ''}

                                    </div>

                                    <div className="row text-center btn-com-sec" >

                                        <div className="col-lg-12 d-flex justify-between-end mt-2">
                                            <a href={link.telegramLink} target="_blank" rel="noreferrer" className="social-icons-sec" style={{ 'minWidth': '0px' }}>
                                                <i className="icon-layout fab fa-telegram f-30"></i>
                                            </a>
                                            <a href={link.facebookLink} target="_blank" rel="noreferrer" className="social-icons-sec" style={{ 'minWidth': '0px' }}>
                                                <i className="icon-layout fab fa-facebook f-30"></i>
                                            </a>
                                            <a href={link.instagramLink} target="_blank" rel="noreferrer" className="social-icons-sec" style={{ 'minWidth': '0px' }}>
                                                <i className="icon-layout fab fa-instagram f-30"></i>
                                            </a>
                                            <a href={link.linkedInLink} target="_blank" rel="noreferrer" className="social-icons-sec" style={{ 'minWidth': '0px' }}>
                                                <i className="icon-layout fab fa-linkedin f-30"></i>
                                            </a>
                                            <a href={link.twitterLink} target="_blank" rel="noreferrer" className="social-icons-sec" style={{ 'minWidth': '0px' }}>
                                                <i className="icon-layout fab fa-twitter f-30"></i>
                                            </a>
                                            <a href={link.tiktokLink} target="_blank" rel="noreferrer" className="social-icons-sec" style={{ 'minWidth': '0px' }}>
                                                <img alt="tik-tol" className='icon-layout' style={{ width: '30px', 'height': '30px' }} src="/assets/images/tik-tok.png" />
                                            </a>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-6 col-md-6 text-center">
                                    {/* <button type="button" className="btn btn-primary" onClick={()=>{ props.history.push('/register')} }>
                                    Register</button> */}

                                    <button className='text-light btn btn-success mr-3'>
                                        <a href="/login" className="ml-2 f-18 text-light">
                                            {siteConstants.LABEL_SIGNIN}</a></button>
                                    <button className='text-light btn btn-success'>
                                        <a href="/register" className="ml-2 f-18 text-light">
                                            {siteConstants.SIGNUP_NOW}</a></button>

                                    {/* <button type="button" className="btn btn-primary" onClick={()=>{ props.history.push('/login')} }>
                                    Login</button> */}
                                </div>
                            </div>
                        </div>

                    </div>

                    {
                        (randomTextAdvertismentList || randomAdvertismentList) &&
                            (randomTextAdvertismentList.length > 0 || randomAdvertismentList.length > 0) ?
                            <div className=''>
                                {/* Text Ads */}
                                <div className='row my-2 register-banner-ads'>
                                    <div className='col-md-12 d-flex flex-row justify-content-center home-text-ads'>
                                        {randomTextAdvertismentList ? (
                                            randomTextAdvertismentList.length > 0 ? (
                                                randomTextAdvertismentList.map((data, index) => {
                                                    if (data.type === TEXT_TEMPLATE1 || data.type === TEXT_TEMPLATE2) {
                                                        return bannerHtml1(data, index)
                                                    }
                                                })
                                            ) : (
                                                ""
                                            )) : (
                                            ""
                                        )}
                                    </div>
                                </div>

                                {/* Banner Ads */}
                                <div className=' d-flex flex-column mt-2 register-banner-ads justify-content-center'>
                                    <div className='row'>
                                        {randomAdvertismentList ? (
                                            randomAdvertismentList.length > 0 ? (
                                                randomAdvertismentList.map((data, index) => {

                                                    if (data.type === BANNER_ADS) {
                                                        if (index < 2) {
                                                            return bannerHtml2(data, index)
                                                        }
                                                        else {
                                                            return bannerHtml2(data, index)
                                                        }
                                                    }
                                                })
                                            ) : (
                                                ""
                                            )) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                            : ''}
                </div>

            </section>

        </>
    );
}

export default PreRegistration;