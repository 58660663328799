import React, { useState, useEffect } from 'react';
import Alert from 'react-bootstrap/Alert';
import { walletAction, alertActions } from '../../actions';
import { apiConstants } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../Common/Pagination/Pagination';
import Header from '../Common/Dashboard/Header';
import Footer from '../Common/Dashboard/Footer';
import Sidebar from '../Common/Dashboard/Sidebar';
import BannerRandomFooter from '../Common/Dashboard/BannerRandomFooter';
// import moment from 'moment-timezone'
import Select from 'react-select';
var momentTimeZone = require('moment-timezone');

export default function WalletTransactions() {
  const dispatch = useDispatch();
  const [alertRes, setAlertRes] = useState('');
  const [transactionList, setTransactionList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchVal, setSearchVal] = useState({ 'value': 'positionNumber', 'label': 'By Position' },);
  const [totalPages, setTotalpages] = useState(1);
  const [siteConstants, setSiteConstants] = useState([]);
  const [getAddress, setGetAddress] = useState('');
  const [query, setQuery] = useState('');
  const timeZone = momentTimeZone.tz.setDefault("America/New_York");
  const [planType, setPlanType] = useState('');
  const selectedSearch = [
    { 'value': 'positionNumber', 'label': 'By Position' },
    { 'value': 'boardNumber', 'label': 'By Board' }]

  const { alert, language, getTransaction } = useSelector(
    state => ({
      alert: state.alert,
      language: state.language,
      getTransaction: state.getTransaction
    })
  );

  const initialApiCalls = () => {
    let sessionPlan = sessionStorage.getItem('planType');
    let obj = {
      page: 1,
      perPage: 10,
      searchBy: searchVal.value,
      plan: { _id: sessionPlan ? JSON.parse(sessionPlan)._id : '' }
    };
    dispatch(alertActions.clear());
    dispatch(walletAction.getTransaction(apiConstants.GET_USER_TRANSACTION, obj));
  }

  useEffect(() => {
    initialApiCalls()
    return () => {
      setAlertRes('')
    }
  }, []);

  useEffect(() => {
    initialApiCalls()
  }, [planType])

  useEffect(() => {
    if (Object.keys(alert).length > 0) {

      let tempErr = [];
      if (alert.type === 'alert-success') {
        tempErr = [{ 'type': alert.type, 'message': alert.message }]
      }
      else if (alert.type === 'alert-danger') {
        if (alert.message.errors !== undefined) {

          if (typeof alert.message.errors == 'object') {

            let keys = Object.keys(alert.message.errors)

            if (keys.length !== 0) {
              keys.map(data => {
                return tempErr.push({ 'type': alert.type, message: alert.message.errors[data] })
              })
            }
          }
          else {
            tempErr = [{ 'type': alert.type, message: alert.message.errors }]
          }
        }
        else {
          tempErr = [{ 'type': alert.type, message: alert.message }]
        }

      }

      setAlertRes(tempErr);
      setTimeout(() => {
        setAlertRes('')
      }, 3000);
    }


  }, [alert]);

  useEffect(() => {
    if (Object.keys(language).length > 0) {
      setSiteConstants(language.siteConstants);
    }

    if (Object.keys(getTransaction).length > 0) {
      if (getTransaction.getTransaction !== undefined) {
        if (getTransaction.getTransaction.data !== undefined) {
          const { totalPages, currentPage, data } = getTransaction.getTransaction.data.data;
          if (data.length > 0) {
            setGetAddress(getTransaction.getTransaction.data.data.walletAddress)
            setTransactionList(data);
            setCurrentPage(currentPage)
            setTotalpages(totalPages);
          }
        }
      }
    }
  }, [language, getTransaction]);

  const makeHttpRequestWithPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    let obj = {
      page: pageNumber,
      perPage: 10,
      searchBy: searchVal.value
    };
    if (query !== '') {
      obj.query = query;
    }
    dispatch(walletAction.getTransaction(apiConstants.GET_USER_TRANSACTION, obj));
  }

  const searchPosition = (e) => {
    let obj = {
      page: 1,
      perPage: 10
    };
    setQuery(e.target.value);
    if (e.target.value !== '') {
      obj.query = e.target.value;
      obj.searchBy = searchVal.value;

      dispatch(walletAction.getTransaction(apiConstants.GET_USER_TRANSACTION, obj));
    } else {
      dispatch(walletAction.getTransaction(apiConstants.GET_USER_TRANSACTION, obj));
    }
  }

  const planHandler = (e) => {
    setPlanType(e.details)
  }

  let totalPage = typeof transactionList !== 'undefined' && Object.keys(transactionList).length > 0 ? totalPages : 0;
  let tempArr = [1, 2];
  return (
    <div className="main-wrap">
      <Header changePlan={(e) => { planHandler(e) }} />
      <div className="dashboard-wraper">
        <Sidebar />
        <div className="content-wrap">
          {alertRes !== '' ?
            alertRes.length > 0 ? alertRes.map((data, index) => {

              return (
                <Alert key={index} variant={data.type === 'alert-danger' ? 'danger' : 'success'}
                  className="alertBox">
                  {data.message}
                </Alert>)
            }) : '' : ''}

          <div className="dashboard-content p">

            <div className="dash-body-blk">
              <div className="dash-report-blk px-xl-4 px-2 pt-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row progress-bar-sec">
                      <div className="col-xl-12 col-lg-12 col-md-12 mb-4 mb-new inner-progress-bar-sec">
                        <div className="card dash-card tag-block p-3 box_shadow">
                          <div className="row">
                            <div className="col-lg-12">  <h2 className="heading-top">Wallet Transactions</h2>  </div>
                          </div>

                          <div className="row mt-2 mb-2">
                            <div className="col-sm-12 col-md-12 d-flex justify-content-between">
                              <div className="col-md-3">
                                <Select
                                  value={searchVal}
                                  name="searchVal"
                                  placeholder="Search By"
                                  onChange={(e) => { setSearchVal(e) }}
                                  options={selectedSearch}
                                />
                              </div>
                              <div id="dataTable_filter" className="dataTables_filter">
                                <label>
                                  <input type="search" placeholder="search (by position number)"
                                    className="form-control form-control-sm"
                                    aria-controls="dataTable"
                                    onChange={(e) => searchPosition(e)} />

                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="owned-sec scroll-layout transaction-layout">
                            <table className="m-2">
                              <thead>
                                <tr>
                                  <td>ID</td>
                                  {/* <td>From</td>
                                  <td>To</td> */}
                                  {/* <td>Type</td> */}
                                  <td>Remark</td>
                                  <td>Credit</td>
                                  <td>Debit</td>

                                  <td>Time</td>
                                  <td>Position Number</td>
                                  <td>Board Number</td>
                                  <td>Round Number</td>
                                  <td>Transfer In</td>
                                </tr>

                              </thead>
                              <tbody>
                                {
                                  transactionList !== undefined && transactionList.length > 0 ?
                                    transactionList.map((item, index) => {

                                      return (item.to.to === item.from.from ?
                                        tempArr.map((data, i) => {
                                          return (
                                            <tr key={index + i}>
                                              <td>{data === 1 ? index + 1 : index + 1 + '(a)'}</td>
                                              <td>{item.remark !== null ?
                                                data === 1 ? item.remark.credit : item.remark.debit : ''}</td>

                                              <td>{item.to !== null ? data === 1 ? parseFloat(item.amount).toFixed(2) : '' : ''}</td>
                                              <td>{item.from !== null ? data === 2 ? parseFloat(item.amount).toFixed(2) : '' : ''}</td>
                                              <td>{momentTimeZone.tz.guess() !== null && momentTimeZone.tz.guess() !== undefined ?
                                                momentTimeZone(item.createdAt).tz(momentTimeZone.tz.guess()).format('DD-MM-YYYY HH:mm:ss')
                                                :
                                                momentTimeZone(item.createdAt).tz(timeZone).format('DD-MM-YYYY HH:mm:ss')}</td>
                                              {/* THIS NOT TAKING VALUE FROM .env <td>{Moment(item.createdAt).format(process.env.FULL_DATE_FORMAT)}</td> */}
                                              <td>{item.positionNumber !== undefined && item.positionNumber !== null ? item.positionNumber.positionNumber : ''}</td>
                                              <td>{item.boardNumber !== undefined && item.boardNumber !== null ? item.boardNumber.positionNumber : ''}</td>
                                              {
                                                item.roundNumber == "0" ?  
                                                <td>{item.roundNumber !== undefined && item.roundNumber !== null ? Number(item.roundNumber) + 1 : ''}</td>
                                                : 
                                                <td>{item.roundNumber !== undefined && item.roundNumber !== null ? item.roundNumber : ''}</td>
                                              }
                                             
                                              <td>{item.transferTo !== undefined && item.transferTo !== null ? item.transferTo.positionNumber : ''}</td>
                                            </tr>)
                                        })

                                        :

                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{item.remark !== null ? item.remark.credit !== undefined ?
                                            item.to.to === getAddress ? item.remark.credit : item.remark.debit : item.remark.debit
                                            : item.remark}</td>

                                          <td>{item.to !== null ?
                                            item.to.to !== null ? item.to.to === getAddress ? parseFloat(item.amount).toFixed(2) : '' : '' : ''}</td>
                                          <td>{item.from !== null ?
                                            item.from.from !== null ? item.from.from === getAddress ? parseFloat(item.amount).toFixed(2) : '' : '' : ''}</td>
                                          <td>{momentTimeZone.tz.guess() !== null && momentTimeZone.tz.guess() !== undefined ?
                                            momentTimeZone(item.createdAt).tz(momentTimeZone.tz.guess()).format('DD-MM-YYYY HH:mm:ss')
                                            :
                                            momentTimeZone(item.createdAt).tz(timeZone).format('DD-MM-YYYY HH:mm:ss')}</td>
                                          {/* THIS NOT TAKING VALUE FROM .env <td>{Moment(item.createdAt).format(process.env.FULL_DATE_FORMAT)}</td> */}
                                          <td>{item.positionNumber !== undefined && item.positionNumber !== null ? item.positionNumber.positionNumber : ''}</td>
                                          <td>{item.boardNumber !== undefined && item.boardNumber !== null ? item.boardNumber.positionNumber : ''}</td>
                                          <td>{item.roundNumber !== undefined && item.roundNumber !== null ? item.roundNumber : ''}</td>
                                          <td>{item.transferTo !== undefined && item.transferTo !== null ? item.transferTo.positionNumber : ''}</td>
                                        </tr>)
                                    }) : <tr>
                                      <td colSpan="8" className="noRecordFound">{siteConstants.GEN_NO_RECORD}</td>
                                    </tr>
                                }


                              </tbody>
                            </table>
                            {typeof transactionList !== 'undefined' ?
                              <Pagination totalPage={totalPage} currentPage={currentPage} funPagination={makeHttpRequestWithPage} />
                              : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Banner Scetion */}
            <div className='col-md-12 mt-3'>
              <BannerRandomFooter />
            </div>
          </div>
          <Footer />
        </div>
      </div>

    </div >
  )
}
